import FaqBody from "./FaqBody";

function App() {
  return (
    <div className="App">
      <FaqBody />
    </div>
  );
}

export default App;
