const colors = {
  white: "#ffffff",
  lightPink: "#F9F0FF",
  grayishPurple: "#8C6991",
  darkPurple: "#2F1533",
  link: "#3E52A3",
};

export {
  colors
}